import React, { Component } from "react";

import "../css/style.css";

import PopupContent from "./popup-text";
import ReactHtmlParser from 'react-html-parser';
import PlusSolidIcon from '../images/icons/plus-solid.svg';


class programlistinginfoug extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isContentVisible: false
        };
        this.slideContent = this.slideContent.bind(this);
      }
    
      slideContent() {
        this.setState(prevState => ({
          isContentVisible: !prevState.isContentVisible
        }));
      }
      
      componentDidMount() {
        // You can use console.log or any other logic here
        //console.log("props.UniversityImage->", this.props.UniversityImage);
      }


	render() {
        const { isContentVisible } = this.state;
        let degreePopupText;
        if(this.props.ShortEligibility != "") {
        degreePopupText = `
      <div class="degree-popup-content">
        <h5 class="mb-0"><span class="font-weight-500">${this.props.UniversityName}</span></h5>
        <p><strong>Eligibility:</strong> ${this.props.ShortEligibility}</p>
        ${this.props.YouSave.length > 0 ? (
          `<p class="font-weight-500"> By starting with The WorldGrad you save: <br /><span class="font-weight-500 text-fast-blue ">${this.props.YouSave}</span></p>`
        ) : (
          ""
        )}
        <div class="process-step-item-holder">
          <div class="col-12 p-0 process-step-style-02 wow animate__fadeIn" data-wow-delay="0.2s">
            <div class="process-step-item">
              <div class="process-step-icon-wrap">
                <div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight- 500"></div>
                <span class="process-step-item-box-bfr bg-medium-gray"></span>
              </div>
              <div class="process-content last-paragraph-no-margin">
                <p class="xs-w-100 mb-0 font-weight-500 text-extra-dark-gray margin-5px-bottom">${this.props.Term1Title}</p>
                <p class="xs-w-100">${this.props.Term1Info}</p>
              </div>
            </div>
          </div>
          ${
            this.props.Term2Title !== "" && this.props.Term2Title !== null
              ? `
          <div class="col-12 p-0 process-step-style-02 wow animate__fadeIn" data-wow-delay="0.4s">
            <div class="process-step-item">
              <div class="process-step-icon-wrap">
                <div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight-500"></div>
                <span class="process-step-item-box-bfr bg-medium-gray"></span>
              </div>
              <div class="process-content last-paragraph-no-margin">
                <p class="xs-w-100 mb-0 font-weight-500 text-extra-dark-gray margin-5px-bottom">${this.props.Term2Title}</p>
                <p class=" xs-w-100">${this.props.Term2Info}</p>
              </div>
            </div>
          </div>
          `
              : ""
          }
          ${
            this.props.Term3Title !== "" && this.props.Term3Title !== null
              ? `
          <div class="col-12 p-0 process-step-style-02 wow animate__fadeIn" data-wow-delay="0.6s">
            <div class="process-step-item">
              <div class="process-step-icon-wrap">
                <div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight-500"></div>
                <span class="process-step-item-box-bfr bg-medium-gray"></span>
              </div>
              <div class="process-content last-paragraph-no-margin">
                <p class="xs-w-100 mb-0 font-weight-500 text-extra-dark-gray margin-5px-bottom">${this.props.Term3Title}</p>
                <p class="xs-w-100">${this.props.Term3Info}</p>
              </div>
            </div>
          </div>
          `
              : ""
          }
        </div>
        <div class="margin-15px-top">
        <p class=" text-center "><a href=${this.props.DetailPageUrl} target=${this.props.Target} class="btn btn-theme effect btn-sm programlink">LEARN MORE<span class="bg-extra-dark-gray"></span></a></p>
      </div></div>
    `;
        }
		return (
            <div class="blog-post-content pb-60  wow fadeInUp br-15 h-100 blog-text overflow-hidden pb-0 bg-white"  data-wow-duration="1s" data-wow-delay=".1s">
             <a href={this.props.DetailPageUrl} class="blog-image border-0 programlink">{this.props.UniversityImage ? <img src={this.props.UniversityImage} class="img-fluid" alt={this.props.UniversityName ? this.props.UniversityName : this.props.UniversityRank} /> : <img src="https://res.cloudinary.com/worldgrad/image/upload/v1611309070/worldgrad/program-enquire-img_sqoqro.png" alt={this.props.UniversityName ? this.props.UniversityName : this.props.UniversityRank} />}
            </a>
            <div class=" d-inline-block w-100">
                <div class="conline-icon-Triangle-ArrowUpt padding-1-half-rem-all lg-padding-1-half-rem-all xs-padding-20px-lr xs-padding-40px-top xs-padding-10px-bottom  position-relative mx-auto ">
                    <a href={this.props.DetailPageUrl} class="blog-details-overlap text-small font-weight-500 bg-fast-blue border-radius-4px alt-font text-white text-uppercase programlink">
           {this.props.UniversityRank}</a>
           {/*{this.props.isFeatured}- {this.props.featuredOrder}-*/}
                    <h6 class="alt-font font-weight-500 margin-10px-top margin-15px-bottom text-extra-dark-gray "><a href={this.props.DetailPageUrl} class="text-extra-dark-gray programlink">{this.props.ProgramName.replace(/&#8211;/g, '-')}, {this.props.PogramDuration}{" "}</a></h6>
                    <span class="alt-font d-block font-weight-500 margin-15px-bottom text-fast-blue text-medium text-uppercase">{this.props.Country}</span>
            <span class="d-block text-extra-dark-gray text-medium  alt-font font-weight-500">
            {this.props.UniversityLogo !== null ? (
								<div class="card-university-logo-holder">
									<img src={this.props.UniversityLogo} alt={this.props.UniversityName ? this.props.UniversityName : this.props.UniversityRank} class={this.props.UniversityName.split(" ").join("").replace("'", "").toLowerCase()+" img-fluid"}/>
								</div>
							) : (
								<div></div>
							)}
                            </span>
                            
                           <div class="d-none-991 position-relative">
                               <PopupContent content={degreePopupText} url={this.props.DetailPageUrl}/>
                           </div>

                           <div class="d-show-991 position-relative">
                               <a onClick={this.slideContent} className="btn btn-theme effect btn-sm uni-read-more-plus-btn"><span> <img src={PlusSolidIcon} alt="+" className="plusicon" /></span></a>
                               <div class={`slideContent ${isContentVisible ? "active" : ""}`} >
                               {ReactHtmlParser(degreePopupText)}
                               </div>
                           </div>
                           
                            
                           
						</div>

                    
              
            </div>
      
                                

            </div>
            
		);
	}
}

export default programlistinginfoug;
